<template>
    <div id="login">
        <jy-login :bg="loginBg" :logo="loginLogo" @login="login" v-model="loading" name="支撑中心"></jy-login>
    </div>
</template>
<script type="text/javascript">
import loginBg from '@/assets/login_bg.png';
import loginLogo from '@/assets/login_logo.png';
export default {
    data() {
        return {
            loginBg,
            loginLogo,
            loading: false,
        };
    },
    methods: {
        login(option) {
            let url = '/adminLogin';
            this.$http.post(url, option).then((data) => {
                console.log(data);
                this.$util.setStor('adminToken', data.detail.token);
                this.$message({
                    message: '登录成功',
                    type: 'success',
                });
                this.$store.commit('setAdminInfo', {
                    ...data.detail,
                    password: option.password,
                });
                console.log(this.$store.state.adminInfo);
                setTimeout(() => {
                    this.$router.push({ name: 'admin' });
                }, 300);
            });
        },
    },
};
</script>
